/* Userregister.css */

/* Ensure the dropdown content is left-aligned */
.left-align-dropdown .country-list {
    text-align: left !important;
  }
  
  /* Each country entry is a flex container aligned to the left */
  .left-align-dropdown .country {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding: 8px 12px !important;
    cursor: pointer !important;
  }
  
  /* Ensure the flag and country name have no extra spacing */
  .left-align-dropdown .flag,
  .left-align-dropdown .country-name {
    margin: 2 !important;
    padding: 0 !important;
    text-align: left !important;
  }
  
  /* Style the search input within the dropdown */
  .left-align-dropdown .search {
    text-align: left !important;
    padding-left: 8px !important;
    border-bottom: 1px solid #ddd !important;
  }
  
  /* Hover effect for country entries */
  .left-align-dropdown .country:hover {
    background-color: #f0f0f0 !important;
  }
  
  /* Align the selected flag properly */
  .left-align-dropdown .selected-flag {
    justify-content: flex-start !important;
  }
  
  
  /* Adjust country name font */
  .left-align-dropdown .country-name {
    font-size: 1rem !important;
    color: #2D3748 !important;
  }
  