/* FloatingWhatsAppOverrides.css */

/* Ensure higher specificity by targeting through the wrapper */
.custom-floating-whatsapp-wrapper .custom-floating-whatsapp .whatsapp-avatar {
    background-color: var(--avatar-bg-color); /* Dynamic background color from React */
    width: 20px; /* Reduced width */
    height: 20px; /* Reduced height */
    border-radius: 50%; /* Make it circular */
    overflow: hidden; /* Ensure the image fits within the container */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Ensure the avatar image fits the container */
  .custom-floating-whatsapp-wrapper .custom-floating-whatsapp .whatsapp-avatar img {
    width: 80%; /* Reduce image size */
    height: 80%;
    object-fit: cover; /* Maintain aspect ratio and cover the container */
  }
  